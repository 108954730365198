<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12"> 
                <Datatable
                    :isLoading="tableLoading"
                    :tableHeaders="tableHeaders"
                    :tableData="tableData"
                    :page="tablePage"
                    :pageLimit="tableLimit"
                    :itemTotal="tableItemTotal"
                    @options-update="onTableOptionsChange($event)"
                    enableExport
                    @export-clicked="exportData()"
                ></Datatable>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';
import ExcelMixin from '@/mixins/ExcelMixin.vue';

export default {
    name: 'Subscription',
    mixins: [ExcelMixin],
    components: {
        Datatable,
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        exportLoading: false,

        // --- static data
        tableHeaders: [
            { text: 'Submission Date', value: 'create_date', width: 200 },
            { text: 'Email', value: 'email' },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getSubscriptionData() {
            this.tableLoading = true;
            try {
                const payload = {
                    limit: this.tableLimit,
                    page: this.tablePage - 1
                };

               const { data, total } = await this.$Fetcher.GetSubscriptions(payload);
               this.tableItemTotal = total;
               this.tableData = data.map(item => {
                return {
                    id: item.id,
                    create_date: item.create_date,
                    email: item.email
                }
               })
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.tableLoading = false;
            }
        },

        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getSubscriptionData();
        },

        async exportData() {
            if (this.exportLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.exportLoading = true;

            try {
                const header = {
                    create_date: 'Submission Date',
                    email: 'Email',
                }

                const payload = {
                    limit: this.tableLimit,
                    page: this.tablePage - 1
                };

               const { data } = await this.$Fetcher.GetSubscriptions(payload);
                const exportData = data.map(el => {
                    return {
                        create_date: this.$Formatter.displayCreateDate(el.create_date, false),
                        email: el.email,
                    }
                });

                this.exportExcel(
                    header,
                    exportData,
                    'Subscription_Form',
                    `Subscription_Form_${this.$Formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
                );
            } catch(err) {
                this.$common.error(err);
                if (Array.isArray(err) && err.length < 1) {
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: 'No data to be exported',
                        type: 'error',
                        refresh: false,
                        redirect: ''
                    });
                } else {
                    this.setDialogMessage({
                        title: "Exported Fail",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                }
               
                
            } finally {
                this.exportLoading = false;
            }
        },
    },
    async created() {
        this.tableLoading = true;
        this.getSubscriptionData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  